@tailwind base;
@tailwind components;
@tailwind utilities;

/* font family add */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Boogaloo&display=swap");

body {
  margin: 0;
  font-family: "Archivo Narrow", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 2s;
  }
  .animation-delay-400 {
    animation-delay: 4s;
  }
}

.bmp_btn {
  color: #fff;
  border-radius: 6px;
  background: linear-gradient(94.99deg, #028ff0 0%, #0dc1d9 98.12%);
}
/* .refLink input {
  color: #fff;
  background: rgba(255, 255, 255, 0.22);
} */

/* accordion css */
.accordion__active .accordion__content {
  opacity: 1;
}

.accordion {
  width: 100%;
  overflow: hidden;
}

.accordion__content {
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: all 0.25s ease-out;
}

.accordion__content.active {
  opacity: 1;
  visibility: visible;
}

/* e-franchises profile */
/* .e-profile-wrap ul li link {
  transition: all 0.3s ease-in-out;
}
.e-profile-wrap ul li a span {
  transition: all 0.2s ease-in-out;
}
.e-profile-wrap ul li a.active,
.e-profile-wrap ul li a:hover {
  background: #0dc1d9;
}
.e-profile-wrap ul li a.active span,
a:hover span {
  color: #fff;
}
.e-profile-wrap ul li a.active span svg path,
a:hover span svg path {
  fill: #fff;
} */
/* .e-profile-wrap ul li a.active span svg path,
a:hover span svg path {
  fill: url(#gradient);
}
.e-profile-wrap ul li a.active span svg defs stop,
a:hover span svg defs stop.start {
  stopColor: #028ff0;
}

.e-profile-wrap ul li a.active span svg defs stop,
a:hover span svg defs stop.end {
  stopColor: #02f0c3;
} */

/* swiper */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.ad_section .swiper .swiper-wrapper {
  align-items: center;
}

.e_profile_right {
  width: 100%;
}

.profit_card #chart-line-tasks {
  height: 350px !important;
}

@media (min-width: 1200px) {
  .e_profile_right {
    width: calc(100% - 250px);
  }
}

@media (min-width: 768px) {
  .e-profile .e_profile_right .referral-content ul.overview_list li {
    width: calc(100% / 3);
  }
}

@media (min-width: 768px) {
  .e-profile .e_profile_right .table-item ul.overview_list li {
    width: calc(100% / 3);
  }
}
@media (max-width: 1199px) {
  .e-profile .profile-left {
    left: -300px;
  }
}
select {
  appearance: none;
  -webkit-appearance: none;
  /* -moz-appearance: none;
  text-indent: 1px; 
  text-overflow: ""; */
  background-color: #fff;
}

/* .chartSize {
  width: 950px !important;
  height: 450px !important;
} */
/* apply e-franchise multi form */
label.uploadFile {
  font-size: 14px;
  border: none;
  color: #000;
  width: 100%;
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 19px;
  background: #ffffff;
  border-radius: 6px;
  font-size: 16px;
  position: relative;
  border: 1px solid #0dc1d9;
}
label.uploadFile span.filename {
  color: #484848;
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
}

label.uploadFile input.inputfile[type="file"] {
  cursor: pointer;
  display: block;
  /* filter: alpha(opacity=0); */
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 0px;
}
.container-fluid {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}
@media (min-width: 576px) {
  .container-fluid {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: 0 auto;
  }
}
input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:defaultchecked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:defaultchecked + label {
  color: #3490dc;
}
#modal_bg {
  animation-name: animatemodal;
  /* animation: linear 0.4s centerme; */
  animation-duration: 0.4s;
  /* animation: centerme 1s;
  -webkit-animation: centerme 1s; */
  position: absolute;
  z-index: 999; /* To ensure that box is always on the top */
  animation-fill-mode: forwards; /* So that box doesn't move back to default place*/
  -webkit-animation-fill-mode: forwards;
}

@keyframes centerme {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 50%;
    top: 50%;
    /* margin-top: -50px;
    margin-left: -50px; */
  }
  /* Left and Top are 50% with margins which are half of width and height */
}

@-webkit-keyframes centerme {
  0% {
    left: 0;
  }
  100% {
    left: 50%;
    /* margin-left: -50px; */
  }
}

/* animation part */
@keyframes animatemodal {
  from {
    top: -1000px;
    opacity: 0;
  }
  to {
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translateY(-50%);
  }
}

.dropdown {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  /* transition: height 0.4s ease-in-out; */
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}
.dropdown.show {
  opacity: 1;
  max-height: 99em;
  transition: max-height 0.3s ease-in-out;
}
/* new mobile menu */
.mobile_menu {
  left: -100%;
}
.mobile_menu.active {
  left: 0;
}

/* banner shape */
.e_banner_b_shap svg {
  margin-left: -23px;
  width: calc(100% + 42.3px);
}

@media (min-width: 1800px) {
  .e_banner_b_shap {
    height: 260px !important;
    top: 93% !important;
  }
}

@media (min-width: 1600px) {
  .e_banner_b_shap {
    height: 200px !important;
    top: 95% !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.rightShadow {
  box-shadow: 5px 0 5px -5px rgb(221, 218, 218);
}

/* e-animation css */
.animate-bubble {
  display: none;
}
@media (min-width: 1200px) {
  .animate-bubble {
    display: block;
  }
}
.animate-bubble .bubble {
  position: absolute;
  border-radius: 50%;
}
.animate-bubble .bubble.a-one,
.animate-bubble .bubble.a-four {
  width: 40px;
  height: 40px;
}
.animate-bubble .bubble.a-two,
.animate-bubble .bubble.a-five {
  width: 30px;
  height: 30px;
}
.animate-bubble .bubble.a-three {
  width: 20px;
  height: 20px;
}
.animate-bubble .bubble.a-six {
  width: 15px;
  height: 15px;
}
.animate-bubble .bubble.a-one {
  top: 40%;
  left: 30%;
  transform: translateY(-50%);
  animation: Yesin 2s infinite alternate;
}
.animate-bubble .bubble.a-two {
  background: #fca249;
  top: 50%;
  left: 155px;
  transform: translateY(-50%);
  animation: spin1 2s infinite alternate;
}
.animate-bubble .bubble.a-three {
  top: 50%;
  right: 50px;
  animation: spin1 1s infinite alternate;
  background: linear-gradient(94.99deg, #028ff0 0%, #0dc1d9 98.12%);
}
.animate-bubble .bubble.a-four {
  top: 60%;
  left: 40%;
  transform: translateY(-40%);
  animation: Yesin 2s infinite alternate;
}
.animate-bubble .bubble.a-five {
  background: #ff9398;
  top: 50%;
  right: 155px;
  transform: translateY(-50%);
  animation: spin1 3s infinite alternate;
}
.animate-bubble .bubble.a-six {
  background: #42bcdb;
  top: 30%;
  left: 45%;
  animation: spin2 2s infinite alternate;
}
.animate-bubble .bubble.a-eight {
  top: 20%;
  left: 70%;
  animation: Yesin 2s infinite alternate;
}
.animate-bubble .bubble.a-nine {
  top: 20%;
  left: 5%;
  animation: spin3 2s infinite alternate;
}
.animate-bubble .bubble.a-ten {
  top: 20%;
  left: 50%;
  animation: Yesin 2s infinite alternate;
}

/* all keyframs css */
@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}
@keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@-webkit-keyframes spin3 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}
@keyframes spin3 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@keyframes Yesin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.gmnoprint[role="menubar"] {
  margin: 10px;
  z-index: 1000000;
  position: absolute;
  cursor: pointer;
  top: 100px !important;
}

.hub_data_list ul li span {
  width: 150px;
  display: inline-block;
}

.hub_data_list ul li p {
  width: 100%;
}

@media (min-width: 576px) {
  .hub_data_list ul li p {
    width: calc(100% - 166px);
  }
}

/* @media (min-width: 992px) {
  .gmnoprint[role="menubar"] {
    margin: 10px;
    z-index: 1000000;
    position: absolute;
    cursor: pointer;
    left: 80% !important ;
    top: 0px;
  }
} */
